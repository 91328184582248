// Gatsby supports TypeScript natively!
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductGrid from "../components/ProductGrid"

const ProductsPage = ({ data }) => (
  <Layout>
    <Seo title="Productos" />
    <div className="container mx-auto px-4">
      <h1 className="p-6  my-4 heading">Productos</h1>
      <ProductGrid products={data.products.nodes} />
    </div>
  </Layout>
)

export default ProductsPage

export const query = graphql`
  query AllProducts {
    products: allMdx(sort: { fields: frontmatter___id }) {
      nodes {
        frontmatter {
          slug
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
