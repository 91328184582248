import React from "react"
import { Link } from "gatsby"

import Card from "../components/Card"
import { getImage } from "gatsby-plugin-image"

const ProductGrid = ({ products, path = "" }) => {
  return (
    <ul className="grid gap-8 grid-cols-4 justify-around">
      {products.map((product, index) => {
        const image = getImage(product.frontmatter.thumb)
        const isEven = index % 2 === 0
        return (
          <li
            key={product.slug}
            className={
              isEven
                ? "row-span-2 col-start-1 col-end-5 lg:col-end-3"
                : `row-span-2 ${
                    index === 1 ? "lg:row-start-2" : ""
                  } col-start-1 col-end-5 lg:col-start-3`
            }
          >
            <Link to={`${path}${product.frontmatter.slug}`}>
              <Card
                title={product.frontmatter.title}
                image={image}
                description={product.frontmatter.description}
              />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default ProductGrid
