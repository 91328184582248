import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"

const Card = ({ title, image, description }) => (
  <div className="rounded overflow-hidden shadow-lg">
    <div className="w-full h-64 overflow-hidden">
      <GatsbyImage image={image} className="w-full" alt="producto" />
    </div>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-2">{title}</div>
      <p className="text-gray-700 text-base">{description}</p>
    </div>
    <div className="px-6 pt-4 pb-2">
      <span className="inline-block bg-secondary rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
        #Seguridad
      </span>
      <span className="inline-block bg-secondary rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
        #Alarma
      </span>
      <span className="inline-block bg-secondary rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2">
        #Tecnologia
      </span>
    </div>
  </div>
)

export default Card
